import config from "common/config";
import urlUtils from "common/urlUtils";
import cardHandler from "./card";
import paypalHandler from "./paypal";
import buyBackHadnler from "./buyBack";
import directViewHadnler from "./directView";
import switchHandler from "./switch";
import { appendCheckoutButton } from "./checkout/paypalCheckout";
import { appendCardCheckoutButton } from "./checkout/cardCheckout";
import serviceUtils from "./util";

export default class Handler {
  static cardBuy(urlData) {
    cardHandler(urlData, config);
  }

  static paypayButton(urlData) {
    paypalHandler(urlData, config);
  }

  static buyBack() {
    buyBackHadnler(config);
  }

  static directView() {
    directViewHadnler(config);
  }

  // 区分是button逻辑还是checkout逻辑
  // 假如type=checkout，会同时返回client_id, url，则前端走新的Checkout支付逻辑
  static switch(urlData) {
    try {
      // 如果已经携带clientId过来了，直接挂载，无需再请求后台获取相关的参数
      if (urlData.hasOwnProperty("clientId")) {
        appendCheckoutButton(
          urlData.clientId,
          urlData.rtu,
          urlData.plan_id,
          urlData.actual_effect,
          urlData
        );
      } else {
        switchHandler(urlData, config)
          .then((result) => {
            console.log("switchHandler result", result);
            // return;
            if (result && result.type && result.type === "checkout") {
              // PP checkout模式，会返回实际在页面中显示的数量
              const service_effect = serviceUtils.getServiceEffect(result);
              //init接口分配好了账号和付款的plan之后，不想再在create接口中重复计算一次，避免不一致的风险,所以plan_id会接力传递到creatOrder
              this.paypalCheckout(
                result.client_id,
                result.url,
                result.request_url,
                result.plan_id,
                service_effect,
                urlData
              );
            } else {
              //其他都走PP的button_id的模式
              this.paypayButton(urlData);
            }
          })
          .catch((e) => {
            this.paypayButton(urlData);
          });
      }
    } catch (e) {
      this.paypayButton(urlData);
    }
  }

  static paypalCheckout(
    clientId,
    url,
    request_url,
    plan_id,
    service_effect,
    urlData
  ) {
    const query = {
      clientId,
      rtu: request_url
    };
    plan_id && (query.plan_id = plan_id);
    !urlData.actual_effect && (query.ae = service_effect);
    //后台会返回实际支付请求时候的url,然后重定向到URL中，带上client_id,request_url,actual_effect
    window.location.href =
      url +
        // window.location.origin +
      urlUtils.getUrlQuery(window.location.href) +
      "&" +
      urlUtils.stringify(query);
  }

  // card的checkout
  static async cardSwitch(urlData) {
    if (urlData.hasOwnProperty("stripe_client_id")) {
      appendCardCheckoutButton(
          urlData,
          urlData.stripe_client_id,
          urlData.rtu
      );
    } else {
      const result = await switchHandler(urlData, config, true);
      const {
        stripe_client_id,
        request_url,
        url
      } = result;
      const service_effect = serviceUtils.getServiceEffect(result);
      if (location.origin !== url) {
        const query = {
          sci: stripe_client_id,
          rtu: request_url,
        };
        !urlData.actual_effect && (query.ae = service_effect);
        location.href = url +
            urlUtils.getUrlQuery(window.location.href) +
            "&" +
            urlUtils.stringify(query);
      } else {
        // 如果不存在actual_effect，则用后台计算的service_effect
        !urlData.actual_effect && (urlData.actual_effect = service_effect);
        appendCardCheckoutButton(urlData, stripe_client_id, request_url);
      }
    }
  }
}
