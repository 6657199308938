//===================== [config] ==========================
const env = process.env.NODE_ENV;
const STAGEING = process.env.STAGEING;
const urlMap = [
  {
    locationUrl: "https://www.toptopfar.com",
    iframeUrl: "https://www.snowymall.com",
    stripeKey: "pk_live_yqqo0gvfwDynMNWUrzvCtYsi"
  },
  {
    locationUrl: "https://pay.famepop.me",
    iframeUrl: "https://www.gujamart.com",
    stripeKey: "pk_live_yqqo0gvfwDynMNWUrzvCtYsi"
  }
];
const config = {
  baseUrl: "https://cgweb.testappbeta.com",
  // baseUrl: "http://35.153.130.97:8014",
  paypalUrl: "https://www.sandbox.paypal.com/cgi-bin/webscr",
  fasterPayUrl: "https://pay.sandbox.fasterpay.com/payment/form",
  faster_pay_description: "Test Mode",
  faster_pay_description_description: "Test Mode Subscription",
  basePlatform: "Web_Test",
  stripeId: "pk_test_8XhB5VRcHU3rYIB0Hb1ddatH",
  iframeUrl: ""
};

if (env == "production" && STAGEING == "false") {
  config.baseUrl = window.location.origin;
  config.paypalUrl = "https://www.paypal.com/cgi-bin/webscr";
  config.fasterPayUrl = "https://pay.fasterpay.com/payment/form";
  config.basePlatform = "Web_NGX";
  config.stripeId = "pk_live_yqqo0gvfwDynMNWUrzvCtYsi";
  config.faster_pay_description = "Ecommerce";
  config.faster_pay_description_description = "Ecommerce Subscription";
  for (const item of urlMap) {
    if (window.location.origin === item.locationUrl) {
      config.iframeUrl = item.iframeUrl;
      config.stripeId = item.stripeKey;
      break;
    }
  }
}

export default config;
