class EasyDom {
    constructor(domSelector) {
        if (typeof domSelector === "string") {
            this._domWrap = document.querySelector(domSelector);
        } else if (domSelector instanceof Node) {
            this._domWrap = domSelector;
        }
    }

    getDomNode() {
        return this._domWrap;
    }

    html(content) {
        if (typeof content === "string") {
            this._domWrap.innerHTML = content;
            return this;
        } else {
            throw new Error("html() expect string type param");
        }
    }

    css(opts) {
        if (Object.prototype.toString.call(opts) === "[object Object]") {
            for (const key of Object.keys(opts)) {
                this._domWrap.style[key] = opts[key];
            }
            return this;
        } else if (typeof opts === "string") {
            return this._domWrap.style[opts];
        } else {
            throw new Error("css() expect object type param");
        }
    }

    attr(opts) {
        if (Object.prototype.toString.call(opts) === "[object Object]") {
            for (const key of Object.keys(opts)) {
                this._domWrap.setAttribute(key, opts[key]);
            }
            return this;
        } else if (typeof opts === "string") {
            return this._domWrap.getAttribute(opts);
        } else {
            throw new Error("attr() expect object or string type param");
        }
    }

    hasAttr(opts) {
        if (typeof opts === "string") {
            return this._domWrap.hasAttribute(opts);
        } else {
            throw new Error("hasAttr() expect string type param");
        }
    }

    append(nodeType) {
        if (nodeType instanceof EasyDom) {
            this._domWrap.appendChild(nodeType.getDomNode());
            return this;
        }
        else if (nodeType instanceof Node) {
            this._domWrap.appendChild(nodeType);
            return this;
        } else {
            throw new Error("append() expect Node type param");
        }
    }

    addClass(className) {
        const existedName = this._domWrap.className;
        if (existedName.includes(className)) {
            console.warn(`classname ${className} is existed`);
        } else {
            this._domWrap.className += ` ${className}`;
            return this;
        }
    }

    removeClass(className) {
        const existedNameList = this._domWrap.className.split(" ").map(item => item.trim());
        if (existedNameList.includes(className)) {
            const delIndex = existedNameList.findIndex(item => item === className);
            existedNameList.splice(delIndex, 1);
            this._domWrap.className = existedNameList.join(" ");
            return this;
        } else {
            console.warn(`classname ${className} can not be found`);
        }
    }

    createNode(tagName, attrs) {
        const node = document.createElement(tagName);
        attrs && initDom(node).attr(attrs);
        return node;
    }
}

const initDom = (selector) => new EasyDom(selector);

export default initDom;
