import serviceUtils from "../util";
import {rejectError} from "../fetchCommon";
import {eco_event} from "../../log_event/eco_event";
import utils from "common/utils";
import Fetch from "common/fetch";
import $ from "common/dom";

const getCommonParams = (urlData, client_id, isCard) => {
    const post_data = {
        platform: serviceUtils.getAttribute(urlData, "platform", true),
        app_version: serviceUtils.getAttribute(urlData, "mver", true),
        master_id: serviceUtils.getMasterId(urlData),
        target_user_id: serviceUtils.getTargetUserId(urlData),
        offer_id: serviceUtils.getAttribute(urlData, "offer_id", true),
        [isCard ? "stripe_client_id" : "pp_client_id"]: client_id,
        device_info: {
            customer_user_id: serviceUtils.getAttribute(urlData, "android_id", true),
            android_id: serviceUtils.getAttribute(urlData, "android_id"),
            gaid: serviceUtils.getAttribute(urlData, "gaid"),
            idfv: serviceUtils.getAttribute(urlData, "idfv"),
            idfa: serviceUtils.getAttribute(urlData, "idfa"),
        },
        resource: serviceUtils.getAttribute(urlData, "feed_data"),
        currency_code: "USD",
        pay_way: serviceUtils.getAttribute(urlData, "payby"),
        system_version: serviceUtils.getAttribute(urlData, "system_version"),
        device_model: serviceUtils.getAttribute(urlData, "device_model"),
        price: serviceUtils.getPurePrice(
            serviceUtils.getAttribute(urlData, "price")
        ),
        local_price: serviceUtils.getPurePrice(
            serviceUtils.getAttribute(urlData, "price")
        ),
        lang: serviceUtils.getAttribute(urlData, "lang"),
    };
    // 处理为空的字段
    for (const key in post_data) {
        if (post_data.hasOwnProperty(key)) {
            if (post_data[key] && serviceUtils.isObject(post_data[key])) {
                for (const k in post_data[key]) {
                    post_data[key][k] === undefined || post_data[key][k] === null
                        ? delete post_data[key][k]
                        : void 0;
                }
            } else {
                post_data[key] === undefined || post_data[key] === null
                    ? delete post_data[key]
                    : void 0;
            }
        }
    }
    return post_data;
};

const errorRecord = (url, error) => {
    const isObject = Object.prototype.toString.call(error) === "[object Object]";
    return new Promise((resolve, reject) => {
        const path = "/web/v3/order/error";
        //请求服务器:
        Fetch.req({
            method: "POST",
            baseURL: url ? url : config.baseUrl,
            // baseURL: config.baseUrl,
            url: path,
            data: {
                error: isObject ? JSON.stringify(error) : error,
            },
            encode: true,
            timeout: 10000,
            decode: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((data) => {
                resolve(data);
            })
            .catch(() => {
                rejectError(error, path, true);
            });
    });
};

const redirectAfterApprove = (data, urlData) => {
    try {
        data.source_scheme = urlData.source_scheme;
        const backToNativeData = encodeURIComponent(
            JSON.stringify(data)
        );
        utils.$animation.hide();
        utils.$animation.successfulAni();
        eco_event("paypal", data);
        if (urlData.hasOwnProperty("redirect_url")) {
            console.log(1);
            //这种情况是对于 pure web的，如果有redirect_url，则向redirect_url的页面去跳转，带上返回的参数
            window.location.href =
                urlData.redirect_url + backToNativeData;
        } else {
            console.log(2, window.location.origin +
                "/#/successPay/" +
                backToNativeData);
            // redirect.success(urlData.source_scheme, backToNativeData);
            // 和后台确认过订单后，序列化返回的数据，再次重定向到/successPay/的页面，然后页面重新走/successPay/的拦截逻辑
            // TODO 因为部分老安卓自己拦截了/successPay/ 所以通过这种方式触发安卓的拦截，相当于走了老button模式的成功回跳
            setTimeout(() => {
                window.location.href =
                    window.location.origin +
                    "/#/successPay/" +
                    backToNativeData;
            }, 300);
        }
    } catch (e) {
        console.log("redirectAfterApprove e", e)
    }
}

const appendCheckoutButtonAndDesc = (urlData, isCard) => {
    $(".p-b-box").css({ display: "block" });
    const color = "rgb(46, 46, 48)";
    const product_kind = urlData.product_kind;
    let product_count =
        urlData.actual_effect || urlData.product_count;
    const price = urlData.price;
    let kind;
    if (product_kind.includes("subs_like")) {
        kind = "s_l";
        product_count = serviceUtils.calcSubsCount(urlData, product_count, "like");
    } else if (product_kind.includes("subs_follow")) {
        kind = "s_f";
        product_count = serviceUtils.calcSubsCount(
            urlData,
            product_count,
            "follow"
        );
    } else if (product_kind.includes("golden_follow")) {
        kind = "gf";
    } else if (product_kind.includes("lucky_coin")) {
        kind = "lc";
    } else if (product_kind.includes("coin")) {
        kind = "co";
    } else if (product_kind.includes("follow")) {
        kind = "fw";
    } else if (product_kind.includes("like")) {
        kind = "le";
    } else if (product_kind.includes("view")) {
        kind = "vw";
    } else if (product_kind.includes("comment")) {
        kind = "ct";
    } else if (product_kind.includes("vip")) {
        kind = "vp";
        product_count =
            product_count + (parseInt(product_count) > 1 ? " Months" : " Month");
    }
    addTitle(color);
    addOrderTime(color);
    kind && addProduct(kind, color);
    addNumber(product_count, color);
    addPrice(price, color);
    isCard ? addStripeButton() : addPayPalButton();
};
const addTitle = (color) => {
    const title = $().createNode("div");
    $(title)
        .css({
            fontSize: "24px",
            fontWeight: 900,
            color,
            marginBottom: "30px",
        })
        .html("Payment details");
    $("#paypal-checkout").append(title);
};

const addOrderTime = (color) => {
    const orderTime = $().createNode("div");
    const now = new Date().getTime();
    const time = serviceUtils.formatTime(now);
    const orderTimeText = "Order Time: " + time;
    $(orderTime)
        .css({
            color,
            marginBottom: "15px",
        })
        .html(orderTimeText);
    $("#paypal-checkout").append(orderTime);
};

const addProduct = (type, color) => {
    const product = $().createNode("div");
    const productContent = `Product:<img style='height: 16px;margin-left: 5px;vertical-align: text-top' src='../../assets/img/type/${type}.png'/>`;
    $(product)
        .css({
            color,
            marginBottom: "15px",
        })
        .html(productContent);
    $("#paypal-checkout").append(product);
};

const addNumber = (effect, color) => {
    const numberItem = $().createNode("div");
    const orderTimeText = "Number: " + effect;
    $(numberItem)
        .css({
            color,
            marginBottom: "30px",
        })
        .html(orderTimeText);
    $("#paypal-checkout").append(numberItem);
};

const addPrice = (price, color) => {
    const PriceItem = $().createNode("div");
    const PriceItemText = "Price: " + price;
    $(PriceItem)
        .css({
            color,
            marginBottom: "40px",
            fontSize: "20px",
            fontWeight: 700,
        })
        .html(PriceItemText);
    $("#paypal-checkout").append(PriceItem);
};

const addPayPalButton = () => {
    const payPalButton = $().createNode("div", { id: "paypal-button-container" });
    $("#paypal-checkout").append(payPalButton);
};

const addStripeButton = () => {
    const payPalButton = $().createNode("button", { id: "checkout-button" });
    $(payPalButton).html("Checkout")
    $("#paypal-checkout").append(payPalButton);
}

export {
    getCommonParams,
    errorRecord,
    redirectAfterApprove,
    appendCheckoutButtonAndDesc
}
