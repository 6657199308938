export const eco_event = (payway, backData) => {
  try {
    const { receipt_data } = backData;
    const { iap, kind, way } = receipt_data;
    const { price, product_id } = iap;

    window.gtag("event", "purchase", {
      currency: "USD",
      transaction_id: String(new Date().valueOf()),
      value: 1,
      shipping: 0,
      items: [
        {
          id: product_id,
          name: kind,
          brand: payway,
          quantity: 1,
          price: price
        }
      ]
    });
  } catch (error) {
    //
  }
};
