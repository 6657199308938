import Fetch from "common/fetch";
import utils from "common/utils";
import redirect from "common/redirect";
import { eco_event } from "../log_event/eco_event";
import { log_event } from "../log_event/log_event";

// 购买成功跳转回来的逻辑
const handler = config => {
  let fullUrl = window.location.href;
  let backData = JSON.parse(
    decodeURIComponent(
      fullUrl.substring(fullUrl.indexOf("successPay") + "successPay".length + 1)
    )
  );
  console.log("backData", backData);
  if (backData.hasOwnProperty("err")) {
    //购买失败 后台可能不返回source_scheme 只能toast 避免回跳undefined
    if (backData.source_scheme) {
      setTimeout(() => {
        redirect.fail(backData.source_scheme, backData.err);
      }, 1200);
    } else {
      utils.$toast.error(backData.err);
    }
    // log
    log_event("[error]:backData err", backData.err);
  } else if (JSON.stringify(backData) == "{}") {
    //数据为空
    setTimeout(() => {
      redirect.empty(backData.source_scheme);
    }, 1200);
  } else {
    //购买成功
    if (backData.pay_way && backData.pay_way === "fasterpay") {
      //faster pay
      Fetch.request({
        method: "POST",
        baseURL: config.baseUrl,
        url: "/web/v2/order/create/confirm",
        data: {
          order_id: backData.order_id
        },
        timeout: 10000
      })
        .then(({ data }) => {
          console.log("create/confirm data:", data);
          // event
          eco_event("faster_pay", data.data);
          let resData = JSON.parse(JSON.stringify(data.data));
          const backToNativeData = encodeURIComponent(JSON.stringify(resData));
          // redirect_url优先级比source_scheme高
          if (resData.hasOwnProperty("redirect_url")) {
            console.log("back url:", resData.redirect_url + backToNativeData);
            window.location.href = resData.redirect_url + backToNativeData;
          } else {
            redirect.success(backData.source_scheme, backToNativeData);
          }
        })
        .catch(({ errorCode, errorMessage }) => {
          if (errorCode) {
            console.error("error: ", errorCode, errorMessage);
          }
          if (errorMessage) {
            log_event("[error]:create/confirm", errorMessage);
          }
          utils.$toast.error("Payment failure");
          redirect.empty(backData.source_scheme);
        });
    } else {
      // paypal
      // event
      eco_event("paypal", backData);
      setTimeout(() => {
        redirect.success(
          backData.source_scheme,
          encodeURIComponent(JSON.stringify(backData))
        );
      }, 1200);
    }
  }
};

export default handler;
