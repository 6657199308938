import Fetch from "common/fetch";
import utils from "common/utils";
import urlUtils from "common/urlUtils";
import { rejectError } from "./fetchCommon";
import { log_event } from "../log_event/log_event";

// 当urldata中信息为card的逻辑
const handler = (urlData, config) => {
  let post_data = {
    offer_id: urlData.offer_id,
    user_info: urlData.user_info,
    offer_platform: urlData.platform,
    primary_platform: urlData.platform,
    single_pay: true,
    mver: urlData.hasOwnProperty("mver") ? urlData.mver : null,
    source_scheme: urlData.source_scheme
  };
  // 如果feed_data字段存在，则传给接口，否则不传这个字段
  if (urlData.hasOwnProperty("feed_data")) {
    post_data.feed_data = urlData.feed_data;
  }
  //合并对象：
  post_data = Object.assign({}, post_data, utils.getFasterDefaultData(urlData));

  //判断是否有重定向地址：[纯web会传这个字段过来]
  if (urlData.redirect_url) {
    post_data.redirect_url = urlData.redirect_url;
  }

  //请求服务器:
  Fetch.request({
    method: "POST",
    baseURL: config.baseUrl,
    url: "/web/v2/order/create/fasterpay",
    data: post_data,
    encode: true,
    timeout: 10000
  })
    .then(({ data }) => {
      console.log("success data:", data);
      // fasterpay之后需要立马重定向到data中的url地址，并且传data数据给querystring
      if (data.meta.code == 200) {
        const { fasterpay_url } = data.data;
        let rest = {};
        for (const key of Object.keys(data.data)) {
          if (key !== "fasterpay_url") {
            rest[key] = data.data[key];
          }
        }
        const queryData = {
          shouldPostForm: rest,
          pay: "fasterpay",
          rawData: urlData,
        };
        if (fasterpay_url) {
          window.location.href = `${fasterpay_url}?${urlUtils.stringify(
            queryData
          )}`;
        } else {
          utils.$toast.error("redirectUrlOrigin is missing");
        }
      } else if (data.meta.error_message) {
        utils.$toast.warn(data.meta.error_message);
        log_event("[error]:/create/fasterpay", data.meta.error_message);
      }
    })
    .catch(({ errorCode, errorMessage, status }) => {
      rejectError({ errorCode, errorMessage, status }, "/create/fasterpay");
    })
    .finally(() => {
      utils.$animation.hide();
    });
};

export default handler;
