import utils from "common/utils";
import { log_event } from "../log_event/log_event";

// 处理http请求报错
const rejectHttpError = ({ errorCode, errorMessage, status }, logPath, justPoint) => {
    let errMsg = "";
    if (status === 0) {
        console.error("error: ", errorCode, errorMessage);
        errMsg = "server error";
    } else if (status === 1) {
        errMsg = "request timeout";
    } else {
        errMsg = "network error";
    }
    if (errorMessage) {
        log_event(`[error]:${logPath}`, errorMessage);
    }
    !justPoint && utils.$toast.error(errMsg);
}

// 处理服务器报错
const rejectServerError = (error, path, justPoint) => {
    const err = error && error.error_message ? error.error_message : "Unknown error";
    !justPoint && utils.$toast.error(err);
    log_event("[error]:" + path, err);
}

// 三个参数，1、错误对象 2、错误接口名称 3、是否仅打点
export const rejectError = (error, logPath, justPoint) => {
    console.log("rejectError", error, logPath, justPoint);
    if (error && error.fromServer) {
        rejectServerError(error, logPath, justPoint)
    } else {
        rejectHttpError(error, logPath, justPoint)
    }
}


