import $ from "common/dom";
import config from "common/config";
/**
 * Untils工具函数
 * @type {{postPayPalForm: utils.postPayPalForm}}
 */
const utils = {
  postPayPalForm(data) {
    const paypal_form = document.getElementById("post-form");
    paypal_form.action = config.paypalUrl;
    document.getElementById("hosted_button").value = data.button_id;
    document.getElementById("custom").value = data.uuid;
    paypal_form.submit();
  },
  isSubsOffer(urlData) {
    const subOfferKeys = [
      "subscription_day_period",
      "subscription_month_period",
      "subscription_week_period",
    ];
    const isSubOfferKeyExist = subOfferKeys.some((item) => {
      // 先判断是否有key，再判断是否有值，且值必须是number类型大于0的字符串
      return (
        urlData.hasOwnProperty(item) &&
        Boolean(urlData[item]) &&
        !Number.isNaN(Number.parseFloat(urlData[item])) &&
        Number.parseFloat(urlData[item]) > 0
      );
    });
    if (isSubOfferKeyExist) {
      return true;
    } else if (
      urlData.product_kind.includes("subs") ||
      urlData.product_kind == "vip"
    ) {
      return true;
    } else {
      return false;
    }
  },
  getPriceAmount(price) {
    const newPrice = price.replace("$", "").trim();
    return newPrice;
  },
  getFasterDefaultData(urlData) {
    let defaultData = {};
    // const amount = this.getPriceAmount(urlData.price);
    const currency = "USD";
    const description = config.faster_pay_description;
    defaultData = {
      // amount: amount,
      currency: currency,
      description: description,
    };
    if (this.isSubsOffer(urlData)) {
      //新增订阅的描述字段：
      defaultData.recurring_name = config.faster_pay_description_description;
    }
    return defaultData;
  },
  postFasterPayForm(urlData, serverResult) {
    const fater_pay_form = document.getElementById("faster-pay-form");
    // set form post acion url，只能通过属性赋值，不能添加attr，否则不生效!
    fater_pay_form.action = config.fasterPayUrl;
    this.fillFasterPayFrom(urlData, serverResult);
    fater_pay_form.submit();
  },
  fillFasterPayFrom(urlData, serverResult) {
    //再遍历服务器返回需要插入的key,value:
    const defaultData = this.getFasterDefaultData(urlData);
    const newData = Object.assign({}, serverResult, defaultData);
    console.log("newData:", newData);
    for (const key in newData) {
      this.addFasterPayFromInput({
        name: key,
        value: newData[key],
      });
    }
  },
  addFasterPayFromInput(attrObject) {
    const fater_pay_form = $("#faster-pay-form");
    const input_dom = $(document.createElement("input"));
    attrObject.type = "hidden"; //added type:hidden
    input_dom.attr(attrObject);
    fater_pay_form.append(input_dom);
  },
  isEmail(str) {
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    return reg.test(str);
  },
  $toast: {
    warn(content) {
      $("#toast").css({ display: "block" });
      $(".toast-content").html(content);
      $(".toast-icon-warn").css({ display: "block" });
      $(".toast-icon-error").css({ display: "none" });
      setTimeout(() => {
        $(".toast-content").html("");
        $("#toast").css({ display: "none" });
      }, 2000);
    },
    error(content) {
      $("#toast").css({ display: "block" });
      $(".toast-content").html(content);
      $(".toast-icon-error").css({ display: "block" });
      $(".toast-icon-warn").css({ display: "none" });
      setTimeout(() => {
        $(".toast-content").html("");
        $("#toast").css({ display: "none" });
      }, 2000);
    },
  },
  $animation: {
    successfulAni() {
      $("#loading-container").css({ display: "block" });
      $("#loading").html(`<img src="../../assets/gif/successful.gif" />`);
    },
    processingAni() {
      $("#loading-container").css({ display: "block" });
      $("#loading").html(`<img src="../../assets/gif/processing.gif" />`);
    },
    finishProcessingAni() {
      $("#loading-container").css({ display: "block" });
      $("#loading").html(`<img src="../../assets/gif/loading.gif" />`);
    },
    hide() {
      $("#loading-container").css({ display: "none" });
      $("#loading").html("");
    },
  },
  handleProcessForWebSite(data) {
    // 直接从电商网页跳转过来购买，电商网站会传参过来button_id和custom_uuid,我们拿到这两个参数直接向PP进行表单提交，不用进行post_offer_data来获取button_id和custom
    const { button_id, custom } = data;
    const callbackUrl = `${custom}`;
    console.log("callbackUrl", callbackUrl);
    const params = {
      button_id,
      uuid: this.encodeParams(callbackUrl),
    };
    this.postPayPalForm(params);
  },
  encodeParams(word) {
    const AES = require("crypto-js/aes");
    const encUtf8 = require("crypto-js/enc-utf8");
    const padPkcs7 = require("crypto-js/pad-pkcs7");

    const key = encUtf8.parse("9vApxLk5G3PAsJrM");
    const iv = encUtf8.parse("FnJL7EDzjqWjcaY9");
    const aes = AES.encrypt(word, key, {
      iv,
      padding: padPkcs7,
    });
    return aes.ciphertext.toString();
  },
};

export default utils;
