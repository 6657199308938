import $ from "common/dom";

//直接同过域名去访问的，需要加入iframe进行显示的逻辑
const handler = (config) => { 
    console.log("direct view web url:>>>>>>");
    $("#loading-container").css({ display: "none" });
    let iframeContainer = document.createElement("iframe");
    iframeContainer.setAttribute("src", config.iframeUrl);
    iframeContainer.setAttribute("width", "101%");
    iframeContainer.setAttribute("height", "100%");
    iframeContainer.setAttribute("frameborder", "0");
    document.body.style.cssText = "overflow:hidden";
    document.body.appendChild(iframeContainer);
}

export default handler;
